function HorizonDayEventRenderer() {
	var t = this;

	// exports
	t.renderDayEvents = renderDayEvents;
	t.draggableDayEvent = draggableDayEvent; // made public so that subclasses can override
	t.nonDraggableDayEvent = nonDraggableDayEvent; // made public so that subclasses can override
	t.resizableDayEvent = resizableDayEvent;

	t.clientSegments = function () {
		return getClientSegments(clientSegments);
	};

	// imports
	var opt = t.opt;
	var trigger = t.trigger;
	var isPreventEventMovement = t.isPreventEventMovement;
	var isEventDraggable = t.isEventDraggable;
	var isEventResizable = t.isEventResizable;
	var reportEventElement = t.reportEventElement;
	var eventElementHandlers = t.eventElementHandlers;
	var showEvents = t.showEvents;
	var hideEvents = t.hideEvents;
	var eventDrop = t.eventDrop;
	var eventCancelDrop = t.eventCancelDrop;
	var eventResize = t.eventResize;
	var getRowCnt = t.getRowCnt;
	var getColCnt = t.getColCnt;
	var getColWidth = t.getColWidth;
	var getClustering = t.getClustering;
	var allDayRow = t.allDayRow; // TODO: rename
	var colLeft = t.colLeft;
	var colRight = t.colRight;
	var colContentLeft = t.colContentLeft;
	var colContentRight = t.colContentRight;
	var getDaySegmentContainer = t.getDaySegmentContainer;
	var renderDayOverlay = t.renderDayOverlay;
	var clearOverlays = t.clearOverlays;
	var clearSelection = t.clearSelection;
	var getHoverListener = t.getHoverListener;
	var resourceGridRangeToSegments = t.resourceGridRangeToSegments;
	var cellToDate = t.cellToDate;
	var dateToCell = t.dateToCell;
	var cellToCellOffset = t.cellToCellOffset;
	var cellOffsetToDayOffset = t.cellOffsetToDayOffset;
	var dateToDayOffset = t.dateToDayOffset;
	var dayOffsetToCellOffset = t.dayOffsetToCellOffset;
	var calendar = t.calendar;
	var getEventEnd = calendar.getEventEnd;
	var formatDate = calendar.formatDate;

	//Customized for seedcode
	var getDistanceContainer = t.getDistanceContainer;
	var getNubContainer = t.getNubContainer;
	var compressedView = true; //opt('compressedView');
	var fluidMonths = opt('fluidMonths');
	var maxAllDayEvents = opt('maxAllDayEvents');
	var distances = opt('distances');
	var manageClone = t.manageClone;

	var isShare = opt('isShare');

	var noFilterLabel = opt('noFilterLabel');

	var breakoutField;
	var breakout;

	var breakoutItems;
	var clustering;
	var clusteredSegments;

	var clientSegments;

	// Render `events` onto the calendar, attach mouse event handlers, and call the `eventAfterRender` callback for each.
	// Mouse event will be lazily applied, except if the event has an ID of `modifiedEventId`.
	// Can only be called when the event container is empty (because it wipes out all innerHTML).
	function renderDayEvents(events, modifiedEventId) {
		clustering = getClustering();

		breakoutField = opt('horizonBreakoutField');
		breakout = opt('breakout', null, true);

		if (breakoutField === 'resource') {
			breakoutItems = t.getResources(false);
		} else if (breakoutField === 'status') {
			breakoutItems = opt('statusesAll')();
		} else if (breakoutField) {
			// Custom field
			breakoutItems =
				t.breakoutCustomFields && t.breakoutCustomFields.length
					? t.breakoutCustomFields
					: [{id: 'none', name: 'none', status: {}}];
		}

		// do the actual rendering. Receive the intermediate "segment" data structures.
		var segments = _renderDayEvents(
			events,
			false, // don't append event elements
			true // set the heights of the rows
		);

		clientSegments = segments;

		// report the elements to the View, for general drag/resize utilities
		segmentElementEach(segments, function (segment, element) {
			reportEventElement(segment.event, element);
		});
		// attach mouse handlers
		attachHandlers(segments, modifiedEventId);

		// call `eventAfterRender` callback for each event
		segmentElementEach(segments, function (segment, element) {
			trigger('eventAfterRender', segment.event, segment.event, element);
		});
	}

	// Render an event on the calendar, but don't report them anywhere, and don't attach mouse handlers.
	// Append this event element to the event container, which might already be populated with events.
	// If an event's segment will have row equal to `adjustRow`, then explicitly set its top coordinate to `adjustTop`.
	// This hack is used to maintain continuity when user is manually resizing an event.
	// Returns an array of DOM elements for the event.
	function renderTempDayEvent(event, adjustRow, adjustTop) {
		// actually render the event. `true` for appending element to container.
		// Recieve the intermediate "segment" data structures.
		var segments = _renderDayEvents(
			[event],
			true, // append event elements
			false // don't set the heights of the rows - Customized for seedcode so we don't adjust rows if we are constraining month view.
		);

		var elements = [];

		// Adjust certain elements' top coordinates
		segmentElementEach(segments, function (segment, element) {
			if (segment.row === adjustRow) {
				element.css('top', adjustTop);
			}
			elements.push(element[0]); // accumulate DOM nodes
		});

		return elements;
	}

	// Render events onto the calendar. Only responsible for the VISUAL aspect.
	// Not responsible for attaching handlers or calling callbacks.
	// Set `doAppend` to `true` for rendering elements without clearing the existing container.
	// Set `doRowHeights` to allow setting the height of each row, to compensate for vertical event overflow.
	function _renderDayEvents(events, doAppend, doRowHeights) {
		seedcoder.logSplitTime('received events');
		// where the DOM nodes will eventually end up
		var finalContainer = getDaySegmentContainer();
		// the container where the initial HTML will be rendered.
		// If `doAppend`==true, uses a temporary container.
		var renderContainer = doAppend ? $('<div/>') : finalContainer;

		var nubContainer = getNubContainer();

		var segments = buildSegments(events);
		seedcoder.logSplitTime('render segments');
		var html;
		var elements;
		var moreEventsHTML;
		var removedElements = [];

		// calculate the desired `left` and `width` properties on each segment object
		calculateHorizontals(segments);
		seedcoder.logSplitTime('calculate horizontals');

		// build the HTML string. relies on `left` property
		html = buildHTML(segments);
		seedcoder.logSplitTime('build htmnl');

		// render the HTML. innerHTML is considerably faster than jQuery's .html()
		renderContainer[0].innerHTML = html;
		seedcoder.logSplitTime('render container');

		// retrieve the individual elements
		elements = renderContainer.children();
		seedcoder.logSplitTime('render children');

		// if we were appending, and thus using a temporary container,
		// re-attach elements to the real container.
		if (doAppend) {
			finalContainer.append(elements); //This is no longer necessary becasue we are removing dom elements and attaching them to the final container regardless. It doesn't seem to affect performance at all though.
		}
		seedcoder.logSplitTime('append elements');
		// assigns each element to `segment.event`, after filtering them through user callbacks
		resolveElements(segments, elements);
		seedcoder.logSplitTime('resolve segments');
		// Calculate the left and right padding+margin for each element.
		// We need this for setting each element's desired outer width, because of the W3C box model.
		// It's important we do this in a separate pass from acually setting the width on the DOM elements
		// because alternating reading/writing dimensions causes reflow for every iteration.
		segmentElementEach(segments, function (segment, element) {
			segment.hsides = hsides(element, true); // include margins = `true`
		});
		seedcoder.logSplitTime('hsideds');

		//Set the width of each element - Customized for SeedCode. We are removeing the elements from the dom, then performing our css transformations and adding all elements back at once to minimize reflows.
		segmentElementEach(segments, function (segment, element) {
			// var currentElement = element.detach();

			element.width(Math.max(0, segment.outerWidth - segment.hsides));

			// removedElements.push(currentElement);
		});

		// finalContainer.html(removedElements);

		seedcoder.logSplitTime('set widths');

		// Grab each element's outerHeight (setVerticals uses this).
		// To get an accurate reading, it's important to have each element's width explicitly set already.
		segmentElementEach(segments, function (segment, element) {
			segment.outerHeight = element.outerHeight(true); // include margins = `true`
		});

		// Set the top coordinate on each element (requires segment.outerHeight)
		setVerticals(segments, doRowHeights);

		if (!doAppend) {
			//Build nubs
			var nubHTML = '';
			for (var i = 0; i < segments.length; i++) {
				nubHTML += buildNubHTML(segments[i], segments[i].element);
			}

			nubContainer[0].innerHTML = nubHTML;

			var nubs = nubContainer.children();

			resolveNubElements(segments, nubs);

			//Render our distance lines
			if (distances !== false && !clustering) {
				renderDistances(segments);
			}
		}

		seedcoder.logSplitTime('set heights');

		return segments;
	}

	function renderDistances(segments) {
		var rowCnt = getRowCnt();
		var colCnt = getColCnt();
		var anchorCol;
		var html = '';
		var distanceContainer = getDistanceContainer();
		var colWidth = getColWidth();
		var focusDate = calendar.getNow().stripTime();
		var focusCell = dateToCell(focusDate);

		if (focusCell.row < 0) {
			anchorCol = 0;
		} else if (focusCell.row > 0) {
			anchorCol = colCnt - 1;
		} else {
			anchorCol = focusCell.col;
		}
		var focusLeft = colLeft(anchorCol);
		var focusRight = colRight(anchorCol);

		for (var i = 0; i < segments.length; i++) {
			if (segments[i].distance.visible) {
				html += buildDistanceHTML(
					segments[i],
					segments[i].element,
					focusCell.col + colCnt * focusCell.row,
					focusLeft,
					focusRight,
					focusDate
				);
			}
		}

		distanceContainer[0].innerHTML = html;

		var distances = distanceContainer.children();
		resolveDistanceElements(segments, distances);
	}

	// Generate an array of "segments" for all events.
	function buildSegments(events) {
		var eventShownFunc = opt('eventShown');
		var segments = [];
		for (var i = 0; i < events.length; i++) {
			var beforeRenderResult = trigger(
				'beforeEventRender',
				events[i],
				events[i],
				events
			);
			if (beforeRenderResult) {
				continue;
			}
			if (
				eventShownFunc(events[i]) &&
				!events[i].unscheduled &&
				!events[i].isUnavailable &&
				!events[i].isMeasureOnly &&
				!events[i].isMapOnly
			) {
				var eventSegments = buildSegmentsForEvent(events[i]);
				segments.push.apply(segments, eventSegments); // append an array to an array
			}
		}

		return segments;
	}

	// Generate an array of segments for a single event.
	// A "segment" is the same data structure that View.resourceGridRangeToSegments produces,
	// with the addition of the `event` property being set to reference the original event.
	function buildSegmentsForEvent(event) {
		var breakoutData;
		var schedule =
			isShare && event.shareSchedule
				? event.shareSchedule
				: event.schedule;
		if (breakoutField && breakoutField === 'schedule') {
			breakoutData = schedule;
		} else if (breakout && event.breakout) {
			breakoutData = event[event.breakout.field];
		} else if (breakout && schedule.breakout) {
			breakoutData = event[schedule.breakout.field];
		} else if (breakout && !schedule.breakout) {
			breakoutData = null;
		} else {
			breakoutData = event[breakoutField];
		}

		var segments = resourceGridRangeToSegments(
			event.start,
			getEventEnd(event),
			breakoutItems,
			breakoutData,
			breakoutField ? false : true,
			false,
			clustering
		); //Last parameter is set to true forcing the function to ignore resources
		for (var i = 0; i < segments.length; i++) {
			segments[i].event = event;
			//Create nub object
			segments[i].nub = {};
			segments[i].distance = {};
		}

		return segments;
	}

	// Sets the `left` and `outerWidth` property of each segment.
	// These values are the desired dimensions for the eventual DOM elements.
	function calculateHorizontals(segments) {
		var isRTL = opt('isRTL');
		var colCnt = clustering ? clustering.columnCount : getColCnt();
		var lastCol = colCnt - 1;
		var leftFunc;
		var rightFunc;
		var left;
		var right;

		//Get the difference between the last column width and the first (since the last can be bigger or smaller)
		var lastColWidthDiff =
			colRight(lastCol) - colLeft(lastCol) - (colRight(0) - colLeft(0));

		for (var i = 0; i < segments.length; i++) {
			var segment = segments[i];

			// Determine functions used for calulating the elements left/right coordinates,
			// depending on whether the view is RTL or not.
			// NOTE:
			// colLeft/colRight returns the coordinate butting up the edge of the cell.
			// colContentLeft/colContentRight is indented a little bit from the edge.

			leftFunc = (isRTL ? segment.isEnd : segment.isStart)
				? colContentLeft
				: colLeft;
			rightFunc = (isRTL ? segment.isStart : segment.isEnd)
				? colContentRight
				: colRight;

			left = leftFunc(segment.leftCol);
			right = rightFunc(segment.rightCol);

			//Assign segment properties
			segment.left = left;

			if (segment.rightCol !== lastCol) {
				segment.outerWidth = right - left;
			} else {
				segment.outerWidth = right - left - lastColWidthDiff;
			}

			//Assign nub properties
			segment.nub.left = colLeft(segment.leftCol);
			segment.nub.right = colRight(segment.rightCol);

			if (!segment.isStart && !segment.isEnd) {
				segment.nub.direction = 'span';
				segment.nub.showText = false;
			} else if (
				(colCnt / (colCnt - (segment.leftCol + 1) || 1) < 1.4 &&
					colCnt / (segment.rightCol + 1) < 1.4) ||
				(segment.leftCol === 0 && segment.rightCol + 1 === colCnt)
			) {
				if (segment.isStart && segment.isEnd) {
					segment.nub.direction = 'full';
				} else if (segment.isStart) {
					segment.nub.direction = 'right';
				} else {
					segment.nub.direction = 'left';
				}
				segment.nub.showText = false;
			} else if (colCnt / (segment.rightCol + 1) < 1.4) {
				segment.nub.direction = 'right';
				segment.nub.showText = true;
			} else {
				segment.nub.direction = 'left';
				segment.nub.showText = true;
			}
		}
	}

	// Build a concatenated HTML string for an array of segments
	function buildHTML(segments) {
		var html = '';
		for (var i = 0; i < segments.length; i++) {
			html += buildHTMLForSegment(segments[i]);
		}
		return html;
	}

	// Build an HTML string for a single segment.
	// Relies on the following properties:
	// - `segment.event` (from `buildSegmentsForEvent`)
	// - `segment.left` (from `calculateHorizontals`)
	function buildHTMLForSegment(segment) {
		var html = '';
		var isRTL = opt('isRTL');
		var event = segment.event;
		var url = event.url;
		var eventText = segment.nub.showText
			? ''
			: titleEscape(
					(event.title || '').replace(/\n/g, opt('returnSub')),
					event
				);

		//Customized for seedcode
		var addClass =
			(!fluidMonths && t.name === 'month') || compressedView
				? 'event-constrain'
				: '';

		// generate the list of CSS classNames
		var classNames = ['fc-event', 'fc-event-hori'];
		if (isEventDraggable(event)) {
			classNames.push('fc-event-draggable');
		}
		if (event.isDayback) {
			classNames.push('fc-event-dayback');
		}
		if (segment.isStart) {
			classNames.push('fc-event-start');
		}
		if (segment.isEnd) {
			classNames.push('fc-event-end');
		}
		// use the event's configured classNames
		// guaranteed to be an array via `buildEvent`
		classNames = classNames.concat(event.className);
		if (event.source) {
			// use the event's source's classNames, if specified
			classNames = classNames.concat(event.source.className || []);
		}

		// generate a semicolon delimited CSS string for any of the "skin" properties
		// of the event object (`backgroundColor`, `borderColor` and such)
		var skinCss = getSkinCss(event, opt);

		// var imageClass = "featured-image-thumbnail";

		// if (event.featuredImageThumbnailClass) {
		// 	imageClass += " " + event.featuredImageThumbnailClass;
		// }
		if (url) {
			html += "<a href='" + htmlEscape(url) + "'";
		} else {
			html += '<div';
		}
		html +=
			' data-id=' +
			event._id +
			" class='" +
			classNames.join(' ') +
			"'" +
			" data-instance='" +
			segment.instance +
			"'" +
			' style=' +
			"'" +
			'position:absolute;' +
			'left:' +
			segment.left +
			'px;' +
			skinCss +
			"'" +
			'>';
		if (segment.isStart && isEventResizable(event)) {
			html +=
				"<div class='ui-resizable-handle ui-resizable-w" +
				"'>" +
				'&nbsp;&nbsp;&nbsp;' + // makes hit area a lot better for IE6/7
				'</div>';
		}
		// html += event.featuredImageThumbnail ? "<img class=\"" + imageClass + "\" src=\"" + event.featuredImageThumbnail + "\" ></img>" : "";
		html += "<div class='fc-event-inner " + addClass + "'>";
		if (!event.allDay && segment.isStart && !segment.nub.showText) {
			html +=
				"<span class='fc-event-time time'>" +
				htmlEscape(formatDate(event.start, opt('timeFormat'))) +
				'</span>';
		}
		html +=
			"<span class='fc-event-title'>" + eventText + '</span>' + '</div>';
		if (segment.isEnd && isEventResizable(event)) {
			html +=
				"<div class='ui-resizable-handle ui-resizable-" +
				(isRTL ? 'w' : 'e') +
				"'>" +
				'&nbsp;&nbsp;&nbsp;' + // makes hit area a lot better for IE6/7
				'</div>';
		}
		html += '</' + (url ? 'a' : 'div') + '>';

		// TODO:
		// When these elements are initially rendered, they will be briefly visibile on the screen,
		// even though their widths/heights are not set.
		// SOLUTION: initially set them as visibility:hidden ?
		return html;
	}

	function buildNubHTML(segment, element) {
		var event = segment.event;
		var colCnt = getColCnt();
		var colWidth = getColWidth();
		var contentWidth = calendar.getContentWidth();
		var eventTime =
			!event.allDay && segment.isStart && segment.nub.showText
				? "<span class='fc-event-time time'>" +
					htmlEscape(formatDate(event.start, opt('timeFormat'))) +
					'</span>'
				: '';
		var eventText = segment.nub.showText
			? titleEscape(
					(event.title || '').replace(/\n/g, opt('returnSub')),
					event
				)
			: '';

		var eventTextPositionString = !segment.nub.showText
			? 'display: none;'
			: '';
		var minNubOffset = 20;
		var containerPositionString;
		var contentPositionString;
		var nubClass;
		var classNames = [];

		var imageClass = 'featured-image-thumbnail';

		if (event.featuredImageThumbnailClass) {
			imageClass += ' ' + event.featuredImageThumbnailClass;
		}

		if (event.metadata && event.metadata.linkedID) {
			imageClass += ' with-linked';
		}

		// use the event's configured classNames
		// guaranteed to be an array via `buildEvent`
		classNames = classNames.concat(event.className);
		if (event.source) {
			// use the event's source's classNames, if specified
			classNames = classNames.concat(event.source.className || []);
		}

		if (segment.nub.direction === 'span') {
			nubClass = 'nub-container-span';
			containerPositionString =
				'left:' +
				segment.left +
				'px;' +
				'width:' +
				segment.outerWidth +
				'px;';
			contentPositionString = 'width: 100%;';
		} else if (segment.nub.direction === 'full') {
			nubClass = 'nub-container-full';
			containerPositionString =
				'left:' +
				segment.nub.left +
				'px;' +
				'width:' +
				(segment.nub.right - segment.nub.left) +
				'px;';
			contentPositionString = 'width: 100%;';
		} else if (segment.nub.direction === 'right') {
			nubClass = 'nub-container-right';
			containerPositionString =
				'left:' + '0;' + 'width:' + segment.nub.right + 'px;';
			contentPositionString =
				'padding-right:' +
				(segment.nub.right - segment.nub.left) +
				'px;' +
				'max-width:' +
				nubMaxWidth(segment) +
				'px;';
		} else {
			nubClass = 'nub-container-left';
			containerPositionString = 'left:' + segment.nub.left + 'px;';
			contentPositionString =
				'padding-left: ' +
				(segment.nub.right - segment.nub.left) +
				'px;' +
				'max-width:' +
				nubMaxWidth(segment) +
				'px;';
		}

		//Add class for dayback event
		if (event.isDayback) {
			nubClass += ' nub-container-dayback';
		}

		//Add a class to the nub if the event was modified
		if (event.eventStatus && event.eventStatus.wasModified) {
			nubClass += ' wasModified';
		}

		// Class if the nub wraps the event text which is inside an event
		if (!segment.nub.showText) {
			nubClass += ' wrapped';
		}

		if (!element) {
			containerPositionString += 'display:none;';
		}

		var html =
			'<div data-id=' +
			event._id +
			" data-instance='" +
			segment.instance +
			"'" +
			" class='" +
			'nub-container ' +
			nubClass +
			' ' +
			classNames.join(' ') +
			"'" +
			" style='" +
			'top:' +
			segment.nub.top +
			'px;' +
			containerPositionString +
			"'" +
			'>';
		html += event.featuredImageThumbnail
			? '<img class="' +
				imageClass +
				'" src="' +
				event.featuredImageThumbnail +
				'" ></img>'
			: '';
		if (event.metadata && event.metadata.linkedID) {
			html +=
				'<span class="linked-event"><i class="fa fa-link" aria-hidden="true"></i></span>';
		}
		html +=
			'<div' +
			" class='" +
			'nub-content event-constrain clickable' +
			"'" +
			" style='z-index:8;" +
			contentPositionString +
			"'" +
			'>' +
			"<span class='nub-event-text' style='" +
			eventTextPositionString +
			"'>";

		html +=
			eventTime +
			'<span>' +
			eventText +
			'</span>' +
			'</span>' +
			'</div>' +
			'</div>';

		return html;

		function nubMaxWidth(segment) {
			var width;
			if (segment.nub.direction === 'right') {
				if (segment.nub.left > contentWidth * 0.7) {
					width =
						(contentWidth - (contentWidth - segment.nub.right)) *
						0.7;
				} else if (segment.nub.left > contentWidth * 0.5) {
					width =
						(contentWidth - (contentWidth - segment.nub.right)) *
						0.85;
				} else {
					width =
						contentWidth -
						(contentWidth - segment.nub.right - minNubOffset);
				}
			} else if (segment.nub.direction === 'left') {
				if (segment.nub.right < contentWidth * 0.3) {
					width = (contentWidth - segment.nub.left) * 0.7;
				} else if (segment.nub.right < contentWidth * 0.5) {
					width = (contentWidth - segment.nub.left) * 0.85;
				} else {
					width = contentWidth - segment.nub.left - minNubOffset;
				}
			}
			return width;
		}
	}

	function buildDistanceHTML(
		segment,
		element,
		focusCol,
		focusLeft,
		focusRight,
		focusDate
	) {
		var event = segment.event;
		var colCnt = getColCnt();
		var colWidth = getColWidth();
		var eventTime =
			!event.allDay && segment.isStart && segment.nub.showText
				? htmlEscape(formatDate(event.start, opt('timeFormat')))
				: '';
		var eventText = segment.nub.showText
			? titleEscape(
					(event.title || '').replace(/\n/g, opt('returnSub')),
					event
				)
			: '';
		var containerPositionString;
		var contentPositionString;
		var distanceArrowString;
		var textContainerPositionString;
		var distanceClass = 'distance-container-full';
		var anchor;
		var distanceWidth;
		var days;
		var dayLabel;
		var dayString;
		var dayLabelWidth;

		if (focusCol < segment.leftCol) {
			days = event.start.diff(focusDate, 'days');
			// days = segment.leftCol - focusCol;
			anchor = focusLeft;
			distanceWidth = segment.nub.left - anchor;
			containerPositionString =
				'left:' + anchor + 'px;' + 'width:' + distanceWidth + 'px;';
			contentPositionString = '';
			textContainerPositionString = '';

			if (focusCol < 0) {
				distanceClass = 'distance-container-left';
			}
		} else if (focusCol > segment.rightCol) {
			days = focusDate.diff(event.end, 'days') + 1;
			// days = focusCol - segment.rightCol;
			anchor = focusRight;
			distanceWidth = anchor - segment.nub.right;
			containerPositionString =
				'left:' +
				segment.nub.right +
				'px;' +
				'width:' +
				distanceWidth +
				'px;';
			contentPositionString = '';
			textContainerPositionString = '';

			if (focusCol >= colCnt) {
				distanceClass = 'distance-container-right';
			}
		} else {
			days = 0;
			containerPositionString = 'display: none;';
		}
		//Assign the label we use for days
		dayLabel = days === 1 ? opt('dayText') : opt('daysText');
		dayString = days + ' ' + dayLabel;
		dayLabelWidth = dayString.length * 14 + 5;
		// dayString = (days.toString().length + dayLabel.length + 1) * 14 > distanceWidth ? days : days + " " + dayLabel;

		// distanceArrowString = (days.toString().length) + 40 > distanceWidth ? "display: none;" : "";

		//Adjust for distances too short to display days
		if (focusCol > segment.rightCol && distanceWidth < dayLabelWidth) {
			dayString = days + ' ' + dayLabel;
			containerPositionString =
				'left:' +
				(segment.nub.right - dayLabelWidth) +
				'px;' +
				'width:' +
				dayLabelWidth +
				'px;';
			distanceClass =
				'distance-container-left distance-container-flipped';
			distanceArrowString = '';
		}
		if (focusCol < segment.leftCol && distanceWidth < dayLabelWidth) {
			containerPositionString =
				'left:' +
				(anchor + distanceWidth) +
				'px;' +
				'width:' +
				dayLabelWidth +
				'px;';
			distanceClass =
				'distance-container-right distance-container-flipped';
			distanceArrowString = '';
		}

		if (!element) {
			containerPositionString += 'display: none;';
		}

		var html =
			'<div' +
			" class='" +
			'distance-container ' +
			distanceClass +
			"'" +
			" style='" +
			'top:' +
			(segment.distance.top || segment.top) +
			'px;' +
			containerPositionString +
			"'" +
			'>' +
			'<div' +
			" class='" +
			'distance-content' +
			"'" +
			" style='" +
			contentPositionString +
			"'" +
			'>' +
			"<div class='distance-line-vertical distance-line-vertical-left'></div>" +
			"<div class='distance-arrow-left' style='" +
			distanceArrowString +
			"'></div>" +
			"<div class='distance-arrow-right' style='" +
			distanceArrowString +
			"'></div>" +
			"<div class='distance-line-vertical distance-line-vertical-right'></div>" +
			// "<div class='distance-line-horizontal'></div>" +
			"<div class='distance-line-days-container' style='" +
			textContainerPositionString +
			"'>" +
			"<span class='distance-line-days-content'>" +
			dayString +
			'</span>' +
			'</div>' +
			'</div>' +
			'</div>';

		return html;
	}

	// Associate each segment (an object) with an element (a jQuery object),
	// by setting each `segment.element`.
	// Run each element through the `eventRender` filter, which allows developers to
	// modify an existing element, supply a new one, or cancel rendering.
	function resolveElements(segments, elements) {
		for (var i = 0; i < segments.length; i++) {
			var segment = segments[i];
			var event = segment.event;
			var element = elements.eq(i);

			// call the trigger with the original element
			var triggerRes = trigger('eventRender', event, event, element);

			if (triggerRes === false) {
				// if `false`, remove the event from the DOM and don't assign it to `segment.event`
				element.remove();
			} else {
				if (triggerRes && triggerRes !== true) {
					// the trigger returned a new element, but not `true` (which means keep the existing element)

					// re-assign the important CSS dimension properties that were already assigned in `buildHTMLForSegment`
					triggerRes = $(triggerRes).css({
						position: 'absolute',
						left: segment.left,
					});

					element.replaceWith(triggerRes);
					element = triggerRes;
				}
				segment.element = element;
			}
		}
	}

	function resolveNubElements(segments, elements) {
		for (var i = 0; i < segments.length; i++) {
			var segment = segments[i];
			var event = segment.event;
			var element = elements.eq(i);
			segment.nub.element = element;
		}
	}

	function resolveDistanceElements(segments, elements) {
		for (var i = 0; i < segments.length; i++) {
			var segment = segments[i];
			var event = segment.event;
			var element = elements.eq(i);
			segment.distance.element = element;
		}
	}

	/* Top-coordinate Methods
	-------------------------------------------------------------------------------------------------*/

	// Sets the "top" CSS property for each element.
	// If `doRowHeights` is `true`, also sets each row's first cell to an explicit height,
	// so that if elements vertically overflow, the cell expands vertically to compensate.
	function setVerticals(segments, doRowHeights) {
		var bodyHeight = t.horizonHeight.bodyHeight; //view height is set in view.js currently just for horizon view
		var breakoutTitleRowHeight = t.horizonHeight.breakoutTitleRowHeight;
		var heightOffset = t.horizonHeight.heightOffset;
		var defaultRowHeight = t.horizonHeight.rowHeight - heightOffset;

		// var calendarHeight = opt('height');
		var rowContentHeights = calculateVerticals(segments); // also sets segment.top
		var rowContentElements = getRowContentElements(); // returns 1 inner div per row
		var rowContentTops = [];
		var i;

		var rowHeightLast;
		var offset;
		var totalHeight = 0;

		// Set each row's height by setting height of first inner div
		if (doRowHeights) {
			//Get and add all row content heights
			for (i = 0; i < rowContentElements.length; i++) {
				if (
					breakoutItems &&
					breakoutItems[i].status &&
					breakoutItems[i].status.collapsed
				) {
					// //Adjust last row height based on collapsed rows
					rowHeightLast = 0;
					offset = 2;
				} else {
					rowHeightLast = Math.max(
						rowContentHeights[i],
						defaultRowHeight
					);
					offset = heightOffset;
				}

				//Check for last item
				if (i === rowContentElements.length - 1) {
					if (
						totalHeight +
							rowHeightLast +
							breakoutTitleRowHeight +
							offset <
							bodyHeight &&
						(!breakoutItems ||
							!breakoutItems[i].status ||
							!breakoutItems[i].status.collapsed)
					) {
						rowHeightLast =
							bodyHeight -
							totalHeight -
							breakoutTitleRowHeight -
							offset;
					}
				}

				totalHeight += rowHeightLast + breakoutTitleRowHeight + offset;

				// Set element height
				rowContentElements[i].height(rowHeightLast);
			}
		}

		// Get each row's top, relative to the views's origin.
		// Important to do this after setting each row's height.
		for (i = 0; i < rowContentElements.length; i++) {
			rowContentTops.push(rowContentElements[i].position().top);
		}

		// Set each segment element's CSS "top" property.
		// Each segment object has a "top" property, which is relative to the row's top, but...
		segmentElementEach(segments, function (segment, element) {
			element.css(
				'top',
				rowContentTops[segment.row] + segment.top // ...now, relative to views's origin
			);
			//set nub top and account for row top offset of 4px
			segment.nub.top = rowContentTops[segment.row] - 4 + segment.top;
			//Adjust distance line based on row and account for top offset of 4px
			segment.distance.top =
				rowContentTops[segment.row] - 4 + segment.distance.top;
		});
	}

	// Calculate the "top" coordinate for each segment, relative to the "top" of the row.
	// Also, return an array that contains the "content" height for each row
	// (the height displaced by the vertically stacked events in the row).
	// Requires segments to have their `outerHeight` property already set.
	function calculateVerticals(segments) {
		var rowCnt = getRowCnt();
		var colCnt = getColCnt();
		var rowContentHeights = []; // content height for each row
		var segmentRows = buildSegmentRows(segments); // an array of segment arrays, one for each row
		var colI;

		//Customized for seedcode
		var viewName = t.name;
		var rowContentElements = getRowContentElements();
		var previousDistanceCol;
		var previousEvent;

		var focusDate = calendar.getNow().stripTime();
		var focusCell = dateToCell(focusDate);
		var focusCol = focusCell.col + colCnt * focusCell.row;

		for (var rowI = 0; rowI < rowCnt; rowI++) {
			var segmentTop = 4;
			var segmentRow = segmentRows[rowI];

			//Reset previous distance column for each row
			previousDistanceCol = null;

			// an array of running total heights for each column.
			// initialize with all zeros.
			var colHeights = [];
			for (colI = 0; colI < colCnt; colI++) {
				colHeights.push(0);
			}

			// loop through every segment
			for (var segmentI = 0; segmentI < segmentRow.length; segmentI++) {
				var segment = segmentRow[segmentI];
				segmentTop +=
					(segment.outerHeight + 10) * Math.min(segmentI, 1);
				//Determine placement of distance line and add aditional space if needed if distances is not disabled
				if (distances !== false && !clustering) {
					if (focusCol < segment.leftCol) {
						if (previousDistanceCol !== segment.leftCol) {
							segment.distance.top = segmentTop;
							segment.distance.visible = true;
							segmentTop += 18;
						}
						previousDistanceCol = segment.leftCol;
					} else if (focusCol > segment.rightCol) {
						if (previousDistanceCol !== segment.rightCol) {
							segment.distance.top = segmentTop;
							segment.distance.visible = true;
							segmentTop += 18;
						}
						previousDistanceCol = segment.rightCol;
					}
				}
				// find the segment's top coordinate by looking at the max height
				// of all the columns the segment will be in.
				segment.top = segmentTop;
				// segment.top = arrayMax(
				// 	colHeights.slice(
				// 		segment.leftCol,
				// 		segment.rightCol + 1 // make exclusive for slice
				// 	)
				// );

				// adjust the columns to account for the segment's height
				for (colI = segment.leftCol; colI <= segment.rightCol; colI++) {
					//Add an 8px pad to the bottom
					colHeights[colI] = segment.top + segment.outerHeight + 8;
				}
			}

			// the tallest column in the row should be the  "content height"
			rowContentHeights.push(arrayMax(colHeights));
		}

		return rowContentHeights;
	}

	// Build an array of segment arrays, each representing the segments that will
	// be in a row of the grid, sorted by which event should be closest to the top.
	function buildSegmentRows(segments) {
		var rowCnt = getRowCnt();
		var segmentRows = [];
		var segmentI;
		var segment;
		var rowI;

		// group segments by row
		for (segmentI = 0; segmentI < segments.length; segmentI++) {
			segment = segments[segmentI];
			rowI = segment.row;
			if (segment.element) {
				// was rendered?
				if (segmentRows[rowI]) {
					// already other segments. append to array
					segmentRows[rowI].push(segment);
				} else {
					// first segment in row. create new array
					segmentRows[rowI] = [segment];
				}
			}
		}

		// sort each row
		for (rowI = 0; rowI < rowCnt; rowI++) {
			segmentRows[rowI] = sortSegmentRow(
				segmentRows[rowI] || [] // guarantee an array, even if no segments
			);
		}

		return segmentRows;
	}

	// Sort an array of segments according to which segment should appear closest to the top
	function sortSegmentRow(segments) {
		var sortedSegments = [];

		// build the subrow array
		var subrows = buildSegmentSubrows(segments);

		// flatten it
		for (var i = 0; i < subrows.length; i++) {
			sortedSegments.push.apply(sortedSegments, subrows[i]); // append an array to an array
		}

		return sortedSegments;
	}

	// Take an array of segments, which are all assumed to be in the same row,
	// and sort into subrows.
	function buildSegmentSubrows(segments) {
		// Give preference to elements with certain criteria, so they have
		// a chance to be closer to the top.
		segments.sort(compareClusterEnabledSegments);

		var subrows = [];
		for (var i = 0; i < segments.length; i++) {
			var segment = segments[i];

			// loop through subrows, starting with the topmost, until the segment
			// doesn't collide with other segments.
			for (var j = 0; j < subrows.length; j++) {
				break;
				if (!isDaySegmentCollision(segment, subrows[j])) {
					break;
				}
			}
			// `j` now holds the desired subrow index
			if (subrows[j]) {
				subrows[j].push(segment);
			} else {
				subrows[j] = [segment];
			}
		}

		return subrows;
	}

	// Return an array of jQuery objects for the placeholder content containers of each row.
	// The content containers don't actually contain anything, but their dimensions should match
	// the events that are overlaid on top.
	function getRowContentElements() {
		var i;
		var rowCnt = getRowCnt();
		var rowDivs = [];
		for (i = 0; i < rowCnt; i++) {
			rowDivs[i] = allDayRow(i).find('div.fc-day-content > div');
		}
		return rowDivs;
	}

	/* Mouse Handlers
	---------------------------------------------------------------------------------------------------*/
	// TODO: better documentation!

	function attachHandlers(segments, modifiedEventId) {
		var segmentContainer = getDaySegmentContainer();
		var nubContainer = getNubContainer();

		segmentElementEach(segments, function (segment, element, i) {
			var event = segment.event;
			if (event._id === modifiedEventId) {
				bindDaySeg(event, element, segment);
				segment.nub.element[0]._fci = i;
			} else {
				element[0]._fci = i; // for lazySegBind
				segment.nub.element[0]._fci = i;
			}
		});

		lazySegBind(segmentContainer, segments, bindDaySeg);
		lazyNubBind(nubContainer, segments, bindDaySeg);
	}

	function lazyNubBind(container, segs, bindHandlers) {
		container.unbind('mouseover').mouseover(function (ev) {
			var parent = ev.target,
				e,
				i,
				seg,
				element;
			while (parent != this) {
				e = parent;
				parent = parent.parentNode;
			}
			if ((i = e._fci) !== undefined) {
				e._fci = undefined;
				seg = segs[i];
				element = seg.nub.element.children();
				//Bind nub click and change click target so the target ends up being the event pill
				element.on('click', function (e) {
					e.target = seg.element;
					e.currentTarget = e.target;
					trigger('eventClick', this, seg.event, e);
				});
				$(ev.target).trigger(ev);
			}
			ev.stopPropagation();
		});
	}

	function bindDaySeg(event, eventElement, segment) {
		if (isEventDraggable(event)) {
			t.draggableDayEvent(event, eventElement, segment); // use `t` so subclasses can override
		} else {
			t.nonDraggableDayEvent(event, eventElement, segment);
		}

		if (isEventResizable(event)) {
			t.resizableDayEvent(event, eventElement, segment); // use `t` so subclasses can override
		}

		eventElementHandlers(event, eventElement);
	}

	function draggableDayEvent(event, eventElement, segment) {
		var hoverListener = getHoverListener();
		var dayDelta;
		var eventStart;

		var currentBreakoutField = event.schedule.breakout
			? event.schedule.breakout.field
			: breakoutField;
		var originalBreakoutItem;
		var newBreakoutItem;

		var container = $('.calendar-scroll');
		var containerPosition = container[0].getBoundingClientRect();
		var contentContainer = container[0].querySelector(
			'.fc-event-container'
		);
		var isAltViewDrag;
		var altViewHover;

		var startScroll;
		var eventPosition;
		var isInBounds;
		var isClone;
		var breakout = opt('breakout', null, true);
		var fieldExistsForEvent = opt('fieldExistsForEvent');

		eventElement.draggable({
			delay: 50,
			opacity: opt('dragOpacity'),
			revertDuration: opt('dragRevertDuration'),
			helper: 'clone',
			start: function (ev, ui) {
				calendar.reportAltViewDragStart(ev, ui); // For dragging from calendar into unscheduled
				if (isPreventEventMovement(event)) {
					eventElement.css('opacity', 1);
					return false;
				}

				isClone = manageClone(ev, ui, event);
				if (isClone) {
					ui.helper.append(
						'<div class="add-event-button add-event-button-small"></div>'
					);
				} else {
					eventElement.css('visibility', 'hidden');
				}
				startScroll = container.scrollTop();
				trigger('eventDragStart', eventElement, event, ev, ui);
				hideEvents(event, eventElement);
				segment.nub.element.addClass('dragging');
				hoverListener.start(
					function (cell, origCell, rowDelta, colDelta) {
						eventElement.draggable(
							'option',
							'revert',
							!cell || (!rowDelta && !colDelta && !isClone)
						);
						clearOverlays();
						if (
							cell &&
							(!breakoutField ||
								!breakoutItems[cell.row].status ||
								!breakoutItems[cell.row].status.collapsed)
						) {
							var rangeType = 'days';
							var rangeDelta;
							var origDateCell = {row: 0, col: origCell.col};
							var dateCell = {row: 0, col: cell.col};

							if (clustering) {
								rangeDelta =
									(cell.col - origCell.col) *
									clustering.typeOffset;
								rangeType = clustering.type;
								eventStart = event.start
									.clone()
									.add(rangeDelta, rangeType);
								dayDelta = eventStart.diff(event.start, 'days');
							} else {
								var origCellDate = cellToDate(origDateCell);
								var cellDate = cellToDate(dateCell);
								rangeDelta = cellDate.diff(
									origCellDate,
									'days'
								);
								eventStart = event.start
									.clone()
									.add(rangeDelta, rangeType);
								dayDelta = rangeDelta;
							}

							isInBounds = true;

							originalBreakoutItem = breakoutField
								? breakoutItems[origCell.row]
								: null;
							newBreakoutItem = breakoutField
								? breakoutItems[cell.row]
								: null;

							renderDayOverlay(
								eventStart,
								getEventEnd(event).add(dayDelta, 'days'),
								true,
								breakoutField ? [newBreakoutItem.name] : null
							);
						} else {
							isInBounds = false;
							newBreakoutItem = undefined;
							dayDelta = 0;
						}
					},
					ev,
					'drag'
				);
			},
			//Drag function was added as a fix for container scrolling issues while dragging
			drag: function (ev, ui) {
				isClone = manageClone(ev, ui, event);
				isAltViewDrag = calendar.reportAltViewDrag(
					ev,
					ui,
					contentContainer,
					event,
					isClone
				);
				if (isAltViewDrag) {
					ui.position.top =
						ui.position.top + containerPosition.top - startScroll;
					ui.position.left =
						ui.position.left + containerPosition.left;
					if (!altViewHover) {
						altViewHover = true;
						eventElement.draggable('option', 'revert', false);
						eventElement.draggable('option', 'scroll', false);
					}
				} else {
					eventPosition =
						ui.position.top - startScroll + container.scrollTop();
					ui.position.top = eventPosition;
					if (altViewHover) {
						altViewHover = false;
						eventElement.draggable('option', 'scroll', true);
					}
				}
				trigger('eventDrag', eventElement, event, ev, ui);
			},
			stop: function (ev, ui) {
				var breakoutChanged;
				var preventNoFilterLabelDrag;
				var dragRestriction;

				var hasField;

				var eventBreakoutValue;

				calendar.reportAltViewDragStop(ui, isAltViewDrag); // For dragging from calendar to unscheduled

				if (breakoutField && breakoutField === 'schedule') {
					eventBreakoutValue = event[currentBreakoutField].name;
				} else if (breakout) {
					eventBreakoutValue = Array.isArray(
						event[currentBreakoutField]
					)
						? event[currentBreakoutField].slice(0)
						: event[currentBreakoutField];
				} else {
					eventBreakoutValue = breakoutField
						? event[currentBreakoutField].slice(0)
						: null; //Clone the breakout item array
				}

				isClone = manageClone(ev, ui, event);

				if (!isClone) {
					eventElement.css('visibility', 'visible');
				}

				if (
					newBreakoutItem &&
					Array.isArray(event[currentBreakoutField])
				) {
					//Remove original event resource that we are dragging from and any duplicates
					for (
						var i = event[currentBreakoutField].length;
						i >= 0;
						i--
					) {
						if (
							event[currentBreakoutField][i] ===
								originalBreakoutItem.name ||
							event[currentBreakoutField][i] ===
								newBreakoutItem.name
						) {
							eventBreakoutValue.splice(i, 1);
						}
					}

					eventBreakoutValue.push(newBreakoutItem.name);
				} else if (newBreakoutItem) {
					eventBreakoutValue =
						newBreakoutItem.name === noFilterLabel
							? ''
							: newBreakoutItem.name;
				}

				// Determine if breakout data has changed from dragging
				if (
					newBreakoutItem &&
					originalBreakoutItem.name !== newBreakoutItem.name
				) {
					breakoutChanged = true;
				}

				// Check if the event has the field set by dragging
				hasField = fieldExistsForEvent(
					event.schedule,
					currentBreakoutField
				);

				// Also check if the field has a label that matches and turn hasField to false if there isn't a match
				if (breakout && !event.schedule.breakout) {
					hasField = false;
				}

				hoverListener.stop();
				clearOverlays();
				trigger('eventDragStop', eventElement, event, ev, ui);
				segment.nub.element.removeClass('dragging');

				if (
					!isAltViewDrag &&
					((breakoutChanged && !hasField) ||
						(breakoutChanged && preventNoFilterLabelDrag) ||
						(breakoutChanged && breakoutField === 'schedule') ||
						(breakoutChanged && breakoutField === 'contactName') ||
						(breakoutChanged && breakoutField === 'projectName'))
				) {
					if (preventNoFilterLabelDrag) {
						dragRestriction = 'noFilterDrag';
					} else {
						dragRestriction = breakoutField;
					}
					eventElement.css('filter', ''); // clear IE opacity side-effects
					showEvents(event, eventElement);
					eventCancelDrop(
						this,
						event,
						ev,
						ui,
						breakoutField && breakoutChanged
							? {
									value: eventBreakoutValue,
									field: currentBreakoutField,
									restriction: dragRestriction,
								}
							: null //Test for breakout here to know if we need to set this or not
					);
				} else if (
					isAltViewDrag ||
					dayDelta ||
					breakoutChanged ||
					(isClone && isInBounds)
				) {
					eventDrop(
						this, // el
						event,
						eventStart,
						ev,
						ui,
						breakoutField && breakoutChanged
							? {
									value: eventBreakoutValue,
									field: currentBreakoutField,
								}
							: null, //Test for breakout here to know if we need to set this or not
						isClone,
						isAltViewDrag
					);
				} else {
					eventElement.css('filter', ''); // clear IE opacity side-effects
					showEvents(event, eventElement);
					eventCancelDrop(this, event, ev, ui);
				}
			},
		});
	}

	//What to do if the event can't be dragged
	function nonDraggableDayEvent(event, eventElement) {
		eventElement.draggable({
			start: function (ev, ui) {
				trigger('eventNoDrag', eventElement, event, ev, ui);
				return false;
			},
		});
	}

	function resizableDayEvent(event, element, segment) {
		var isResizing = false;

		var fieldExistsForEvent = opt('fieldExistsForEvent');

		var hasField;
		const handles = element.find('.ui-resizable-handle'); // TODO: stop using this class because we aren't using jqui for this

		// TODO: look into using jquery-ui mouse widget for this stuff
		//disableTextSelection(element); // prevent native <a> selection for IE
		element
			.mousedown(function (ev) {
				// prevent native <a> selection for others
				ev.preventDefault();
			})
			.click(function (ev) {
				if (isResizing) {
					ev.preventDefault(); // prevent link from being visited (only method that worked in IE6)
					ev.stopImmediatePropagation(); // prevent fullcalendar eventClick handler from being called
					// (eventElementHandlers needs to be bound after resizableDayEvent)
				}
			});

		handles.mousedown(function (ev) {
			const direction = ev.currentTarget.classList.contains(
				'ui-resizable-w'
			)
				? 'w'
				: 'e';
			if (isPreventEventMovement(event)) {
				return false;
			}

			if (ev.which != 1) {
				return; // needs to be left mouse button
			}
			isResizing = true;
			var hoverListener = getHoverListener();
			var elementTop = element.css('top');
			var dayDelta;
			let eventStart = event.start.clone();
			var eventEnd = getEventEnd(event);
			var helpers;
			var eventCopy = $.extend({}, event);
			var minCellOffset = dayOffsetToCellOffset(
				dateToDayOffset(
					direction === 'e'
						? eventStart
						: event.allDay
							? eventEnd.clone().add(-1, 'days')
							: eventEnd
				)
			);

			clearSelection();
			segment.nub.element.addClass('resizing');
			$('body')
				.css('cursor', direction + '-resize')
				.one('mouseup', mouseup);
			trigger('eventResizeStart', this, event, ev);
			hoverListener.start(function (cell, origCell) {
				if (cell) {
					const rangeType = 'days';

					if (clustering) {
						var cellOffset =
							direction === 'e'
								? Math.max(cell - origCell, minCellOffset)
								: Math.min(cell - origCell, minCellOffset);
						dayDelta =
							(cell.col - origCell.col) * clustering.typeOffset;
						rangeType = clustering.type;
					} else {
						var origCellOffset = cellToCellOffset(0, origCell.col);
						var cellOffset = cellToCellOffset(0, cell.col);

						// don't let resizing move earlier than start date cell
						cellOffset =
							direction === 'e'
								? Math.max(cellOffset, minCellOffset)
								: Math.min(cellOffset, minCellOffset);

						dayDelta =
							cellOffsetToDayOffset(cellOffset) -
							cellOffsetToDayOffset(origCellOffset);
					}
					// assumed to already have a stripped time
					if (direction === 'w') {
						eventStart = event.start
							.clone()
							.add(dayDelta, rangeType);
					} else {
						eventEnd = getEventEnd(event).add(dayDelta, rangeType);
					}

					if (dayDelta) {
						eventCopy.start = eventStart;
						eventCopy.end = eventEnd;
						var oldHelpers = helpers;
						helpers = renderTempDayEvent(
							eventCopy,
							segment.row,
							elementTop
						);
						helpers = $(helpers); // turn array into a jQuery object
						helpers.find('*').css('cursor', direction + '-resize');
						if (oldHelpers) {
							oldHelpers.remove();
						}
						hideEvents(event);
					} else {
						if (helpers) {
							showEvents(event);
							helpers.remove();
							helpers = null;
						}
					}

					hasField = fieldExistsForEvent(
						event.schedule,
						breakoutField
					);

					clearOverlays();
					renderDayOverlay(
						// coordinate grid already rebuilt with hoverListener.start()
						event.start,
						eventEnd,
						false,
						breakoutField && hasField
							? [event[breakoutField].name]
							: null
						// TODO: instead of calling renderDayOverlay() with dates,
						// call _renderDayOverlay (or whatever) with cell offsets.
					);
				}
			}, ev);

			function mouseup(ev) {
				trigger('eventResizeStop', this, event, ev);
				$('body').css('cursor', '');
				segment.nub.element.removeClass('resizing');
				hoverListener.stop();
				clearOverlays();
				if (dayDelta) {
					eventResize(
						this, // el
						event,
						eventEnd,
						ev,
						null,
						eventStart
					);
					// event redraw will clear helpers
				}
				// otherwise, the drag handler already restored the old events

				setTimeout(function () {
					// make this happen after the element's click event
					isResizing = false;
				}, 0);
			}
		});
	}
}
